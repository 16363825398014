import './ContactButton.css';

function ContactButton() {
    const getBrowserInfo = () => {
      return navigator.userAgent;
    };
  
    const getLocationInfo = () => {
      // You might need user's permission to access the location
      // This is just a placeholder and might not give you accurate location data
      return "Location information not provided"; // Placeholder
    };
  
    const mailtoLink = `mailto:admin@littlelogic.ca?subject=website inquiry&body=Browser Info: ${getBrowserInfo()}%0D%0A${getLocationInfo()}`;
  
    return (
      <a href={mailtoLink} >
        <button className="link-style">Speak to one of our consultants</button>
      </a>
    );
  }
  
  export default ContactButton;
  