// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.link-style {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    text-decoration: underline;
    cursor: pointer;
    color: blanchedalmond;
  }
  
  button.link-style:hover,
  button.link-style:focus {
    color: rgb(112, 112, 218);
    text-decoration: none;
  }
  
  button.link-style:active {
    color: rgb(227, 222, 222);
  }`, "",{"version":3,"sources":["webpack://./src/components/ContactButton.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,UAAU;IACV,aAAa;IACb,0BAA0B;IAC1B,eAAe;IACf,qBAAqB;EACvB;;EAEA;;IAEE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["button.link-style {\n    background: none;\n    border: none;\n    padding: 0;\n    font: inherit;\n    text-decoration: underline;\n    cursor: pointer;\n    color: blanchedalmond;\n  }\n  \n  button.link-style:hover,\n  button.link-style:focus {\n    color: rgb(112, 112, 218);\n    text-decoration: none;\n  }\n  \n  button.link-style:active {\n    color: rgb(227, 222, 222);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
