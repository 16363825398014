// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact {
    padding: 20px;
    text-align: center;
  }
  
  .contact h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .contact p {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Contact.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB","sourcesContent":[".contact {\n    padding: 20px;\n    text-align: center;\n  }\n  \n  .contact h1 {\n    font-size: 2.5rem;\n    margin-bottom: 20px;\n  }\n  \n  .contact p {\n    font-size: 1.25rem;\n    margin-bottom: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
