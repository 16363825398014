import React from 'react';
import './Banner.css'; 

const Banner = () => {
    return (
        <div className="banner">
            <div className="banner-content">
                <h1 className="banner-title">Empowering Business Growth through Innovative IT Solutions</h1>
                <p className="banner-subtitle">We provide tailored IT consulting services and solutions that streamline operations, increase productivity, and drive business success.</p>
            </div>
        </div>
    );
}

export default Banner;
