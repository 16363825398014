import React from 'react';
import BlogPost from '../components/BlogPost';
import wedpic from '../assets/img/wedding.jpg';

const Blog = () => {
  const posts = [
    {
      title: "The Perfect Day",
      subtitle: "Dia del boda",
      image: wedpic,
      content: "March 10, 2023 - The most perefect of days marrying the most beautiful woman in the world.",
      hashtags: ["wedding", "marriage", "vegas", "elvis", "wonderOfYou"],
      imagePosition: "left"
    },
    // {
    //   title: "2nd Post",
    //   subtitle: "This is the subtitle",
    //   image: "/path/to/image1.jpg",
    //   content: "ipsum loreum izzidity bizzity...",
    //   hashtags: ["react", "blog", "design"],
    //   imagePosition: "right"
    // }
  ];

  return (
    <div>
      {posts.map(post => <BlogPost key={post.title} {...post} />)}
    </div>
  );
};

export default Blog;
