// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/img/banner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
    height: 33vh; 
    background: linear-gradient(to right, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 55%, transparent 100%), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center center; /* This will center the image */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.banner-content {
    color: #ffffff;
    position: absolute;
    bottom: 5%; /* Position the text slightly above the bottom */
    max-width: 50%; /* Set a max-width to ensure text doesn't overflow */
}

.banner-title {
    font-size: 2em;
    margin: 0;
}

.banner-subtitle {
    font-size: 1em;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .banner {
        padding-top: 33vh;  /* adjust this based on the height of your header */
    }

    .banner-content {
        max-width: 70%; 
    }
    
    .banner-title {
        font-size: 1.5em;
    }

    .banner-subtitle {
        font-size: 0.9em;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Banner.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yIAAiI;IACjI,sBAAsB;IACtB,kCAAkC,EAAE,+BAA+B;IACnE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,UAAU,EAAE,gDAAgD;IAC5D,cAAc,EAAE,oDAAoD;AACxE;;AAEA;IACI,cAAc;IACd,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA,qCAAqC;AACrC;IACI;QACI,iBAAiB,GAAG,mDAAmD;IAC3E;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".banner {\n    height: 33vh; \n    background: linear-gradient(to right, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 55%, transparent 100%), url('../assets/img/banner.png');\n    background-size: cover;\n    background-position: center center; /* This will center the image */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n}\n\n.banner-content {\n    color: #ffffff;\n    position: absolute;\n    bottom: 5%; /* Position the text slightly above the bottom */\n    max-width: 50%; /* Set a max-width to ensure text doesn't overflow */\n}\n\n.banner-title {\n    font-size: 2em;\n    margin: 0;\n}\n\n.banner-subtitle {\n    font-size: 1em;\n}\n\n/* Media Queries for Mobile Devices */\n@media (max-width: 768px) {\n    .banner {\n        padding-top: 33vh;  /* adjust this based on the height of your header */\n    }\n\n    .banner-content {\n        max-width: 70%; \n    }\n    \n    .banner-title {\n        font-size: 1.5em;\n    }\n\n    .banner-subtitle {\n        font-size: 0.9em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
