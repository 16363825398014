// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    background-color: #333;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  footer p {
    color: #fff;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .social-media-links {
    display: flex;
  }

  .tiny{
    font-size: 0.7em;
  }
  
  .social-media-links a {
    color: #fff;
    text-decoration: none;
    margin: 0 5px;
  }
  
  .social-media-links a:hover {
    color: #00a8ff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,qBAAqB;IACrB,aAAa;EACf;;EAEA;IACE,cAAc;EAChB","sourcesContent":["footer {\n    background-color: #333;\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  footer p {\n    color: #fff;\n    margin: 0;\n    margin-bottom: 10px;\n  }\n  \n  .social-media-links {\n    display: flex;\n  }\n\n  .tiny{\n    font-size: 0.7em;\n  }\n  \n  .social-media-links a {\n    color: #fff;\n    text-decoration: none;\n    margin: 0 5px;\n  }\n  \n  .social-media-links a:hover {\n    color: #00a8ff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
