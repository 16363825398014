import React from 'react';
import './Services.css';
import Service from '../components/Service';
import ContactButton from '../components/ContactButton';
import customDev from '../assets/img/custom_software_dev_1.png';
import webDev from '../assets/img/web_dev_1.png';
import itCons from '../assets/img/it_consulting_1.png';
import mobile from '../assets/img/mobi_app_dev_1.png';
import managed from '../assets/img/managed_it_1.png';
import cloud from '../assets/img/cloud_solu_1.png';
import cyber from '../assets/img/cyber_sec_1.png';
import digital from '../assets/img/digital_trans_1.png';
import busint from '../assets/img/buss_inteli_1.png';

const Services = () => {

  const servicesData = [
    {
        title: "Custom Software Development",
        description: "Unleash the Power of Precision: Tailor-Made Software for Your Unique Business Needs. Ditch the one-size-fits-all software. Our Custom Software Development is about understanding your business intricacies and crafting solutions that fit like a glove.",
        imageUrl: customDev
    },
    {
        title: "Mobile App Development",
        description: "Elevate Your Brand, One Tap at a Time: Exquisite Mobile Experiences Crafted for the Modern User. In a mobile-first world, presence matters. Our Mobile App Development team focuses on intuitive designs and robust functionality.",
        imageUrl: mobile
    },
    {
        title: "Web Development",
        description: "Building Digital Real Estate: Where Form Meets Function for Your Online Presence. Your website is the digital face of your brand. We create responsive, dynamic, and user-friendly websites that don't just look good - they perform.",
        imageUrl: webDev
    },
    {
        title: "IT Consulting",
        description: "Navigating Tech Complexity: Expert Guidance for Every IT Challenge. The digital realm can be daunting. With our IT Consulting, you gain a partner that deciphers the jargon, aligns technology with your goals, and sets a clear path forward.",
        imageUrl: itCons
    },
    {
        title: "Managed IT Services",
        description: "Seamless Operations, Zero Hassles: Proactive IT Management for Uninterrupted Business. The IT hiccups? They're on us. Our Managed IT Services are designed to anticipate and address challenges before they impact your business.",
        imageUrl: managed
    },
    {
        title: "Cloud Solutions",
        description: "Scaling Heights with the Cloud: Agile, Efficient, and Always Accessible. The future is in the cloud, and it's already here. Our Cloud Solutions ensure you leverage the power of cloud computing, be it for storage, collaboration, or infrastructure needs.",
        imageUrl: cloud
    },
    {
        title: "Cybersecurity",
        description: "Guarding Digital Frontiers: Comprehensive Security in an Ever-Evolving Digital Landscape. In an age of increasing threats, your digital assets deserve the pinnacle of protection. Our Cybersecurity suite offers state-of-the-art defense mechanisms.",
        imageUrl: cyber
    },
    {
        title: "Digital Transformation",
        description: "Redefining Business in the Digital Age: Seamless Integration for the Future Ready Enterprise. Evolve or risk obsolescence. Digital Transformation isn't a luxury; it's a necessity. Our experts guide you through a holistic transformation.",
        imageUrl: digital
    },
    {
        title: "Business Intelligence",
        description: "Knowledge is Power: Dive Deep into Data, Emerge with Insights. The answers lie in your data. Our Business Intelligence services decipher patterns, trends, and insights, converting raw data into actionable intelligence.",
        imageUrl: busint
    }
];

  return (
    <div>
      <div className="services-page-container">
          {servicesData.map((service, index) => (
              <Service 
                  key={index}
                  title={service.title}
                  description={service.description}
                  imageUrl={service.imageUrl}
              />
          ))}
        </div>
        <div className="service-box-container">
            <div className="service-container">
                <h3>Hourly Billing: Scale as You Go</h3>
                <p><strong>Rates:</strong> Our hourly billing ranges from <span className="rate">$150 to $400/hour</span>, depending on the seniority of the engineering resources applied.</p>
                <ul>
                    <li><strong>Flexibility</strong>: Perfect for projects that require ongoing changes or updates.</li>
                    <li><strong>Expertise on Demand</strong>: From junior developers to senior engineers.</li>
                    <li><strong>Transparent Tracking</strong>: Keep track of progress with hourly logs.</li>
                    <li><strong>Pragmatic Approaches</strong>: Don't pay for needless superfolous code.  Ever.</li>
                </ul>
            </div>
            <div className="service-container">
                <h3>Custom Packages: Fixed Price, Defined Scope</h3>
                <p><strong>Rates:</strong> Our prices are fixed by items ala carte below.</p>
                <ul>
                    <li><strong>Custom Scripts:</strong> <span className="rate">$1500.00</span> - Specialized scripts for automation or enhanced functionalities.</li>
                    <li><strong>Custom Website:</strong> <span className="rate">$2500.00</span> - Unique, responsive, SEO-optimized websites.</li>
                    <li><strong>Chatbot Development:</strong> <span className="rate">$3000.00</span> - Custom chatbot for efficient customer service.</li>
                    <li><strong>Web3 Solidity:</strong> <span className="rate">$3500.00</span> - Custom Ethereum Remix Solidity contracts.</li>
                    <li><strong>Architecture Review:</strong> <span className="rate">$4000.00</span> - Professional HLD / LLD / EA reviews.</li>
                </ul>
            </div>
        </div>
      <div className="consultation-cta">
        <p><strong>Need guidance?</strong> <ContactButton/> to help decide which model is best for your project.</p>
      </div>
    </div>
  );
};

export default Services;
