import React  from 'react';
import './Home.css';
import Banner from '../components/Banner';
import Services from '../pages/Services';
import Contact from './Contact';
import Blog from './Blog';

const Home = () => {

  return (
    <div className="home">
        <Banner />
        {/* Add more content and components as needed */}
        <section id="services">
            <Services/>
        </section>
        <section id="blog">
            <Blog/>
        </section>
        <section id="contact">
            <Contact/>
        </section>
    </div>
  );
};

export default Home;
