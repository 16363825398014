// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  background-color: #282c34; /* Dark grey background */
  color: #ffffff; /* White text color */
  font-family: 'Roboto', sans-serif; /* Modern sans-serif font */
}

h1, h2, h3, h4, h5, h6 {
  color: #ffa500; /* Stand out color for headings, here it's orange */
}

a {
  color: #61dafb; /* Complementary color for links, here it's light blue */
}

.container {
  max-width: 960px; 
  margin: auto;
  padding: 20px;
}

/* Banner Styles */

.banner {
  height: 100vh;
  background-color: transparent; 
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.banner-content {
  color: #ffffff; /* White text color */
}

.banner-title {
  font-size: 3em;
  line-height: 1.2;
}

.banner-subtitle {
  font-size: 1.5em;
  line-height: 1.5;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,yBAAyB,EAAE,yBAAyB;EACpD,cAAc,EAAE,qBAAqB;EACrC,iCAAiC,EAAE,2BAA2B;AAChE;;AAEA;EACE,cAAc,EAAE,mDAAmD;AACrE;;AAEA;EACE,cAAc,EAAE,wDAAwD;AAC1E;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;AACf;;AAEA,kBAAkB;;AAElB;EACE,aAAa;EACb,6BAA6B;EAC7B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc,EAAE,qBAAqB;AACvC;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  background-color: #282c34; /* Dark grey background */\n  color: #ffffff; /* White text color */\n  font-family: 'Roboto', sans-serif; /* Modern sans-serif font */\n}\n\nh1, h2, h3, h4, h5, h6 {\n  color: #ffa500; /* Stand out color for headings, here it's orange */\n}\n\na {\n  color: #61dafb; /* Complementary color for links, here it's light blue */\n}\n\n.container {\n  max-width: 960px; \n  margin: auto;\n  padding: 20px;\n}\n\n/* Banner Styles */\n\n.banner {\n  height: 100vh;\n  background-color: transparent; \n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n}\n\n.banner-content {\n  color: #ffffff; /* White text color */\n}\n\n.banner-title {\n  font-size: 3em;\n  line-height: 1.2;\n}\n\n.banner-subtitle {\n  font-size: 1.5em;\n  line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
