import React from 'react';
import './BlogPost.css';

const BlogPost = ({ title, subtitle, image, content, hashtags, imagePosition }) => {
  return (
    <div className={`blog-post ${imagePosition}`}>
      <h1 className="blog-title">{title}</h1>
      <h2 className="blog-subtitle">{subtitle}</h2>
      <img src={image} alt={title} className="blog-image" />
      <p className="blog-content">{content}</p>
      <div className="blog-hashtags">
        {hashtags.map(tag => <span key={tag}>#{tag}</span>)}
      </div>
    </div>
  );
};

export default BlogPost;
