import React from 'react';
import './Contact.css';
// import ContactForm from '../components/ContactForm';
import ContactButton from '../components/ContactButton';

const Contact = () => {
  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <p>If you have any questions or need assistance, please reach out to us.</p>
      <p>While we battle a reCaptcha server side issue... pop us an email for now at admin [at] littlelogic [dot] ca</p>
      {/* <ContactForm /> */}
      <ContactButton/>
    </div>
  );
};

export default Contact;
