import React from 'react';
import './Service.css';

const Service = ({ title, description, imageUrl }) => {
    return (
        <div className="service-container">
            <img src={imageUrl} alt={title} className="service-image" />
            <h2 className="service-title">{title}</h2>
            <p className="service-description">{description}</p>
        </div>
    );
}

export default Service;
