import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header>
      <nav>
        <span className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
          ☰
        </span>
        <ul className={`nav-list ${isOpen ? 'open' : ''}`}>
          <li><NavLink to="/" exact activeClassName="active" onClick={() => setIsOpen(false)}>Home</NavLink></li>
          <li><NavLink to="/services" activeClassName="active" onClick={() => setIsOpen(false)}>Services</NavLink></li>
          <li><NavLink to="/blog" activeClassName="active" onClick={() => setIsOpen(false)}>Blog</NavLink></li>
          <li><NavLink to="/contact" activeClassName="active" onClick={() => setIsOpen(false)}>Contact</NavLink></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
