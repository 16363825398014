import React from 'react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <p>© {currentYear} Little Logic Solutions Incorporated.</p>
      <div className="tiny"> Established 2015. <a href="https://www.roblittle.ca" target="_blank" rel="noreferrer">roblittle</a> All Rights Reserved.</div>
      <div className="social-media-links">
        {/* Add social media links here */}
      </div>
    </footer>
  );
};

export default Footer;
