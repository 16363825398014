// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    padding: 2vw;
  }
  
  .home h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .home p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":[".home {\n    padding: 2vw;\n  }\n  \n  .home h1 {\n    font-size: 2.5rem;\n    margin-bottom: 20px;\n  }\n  \n  .home p {\n    font-size: 1.2rem;\n    line-height: 1.6;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
