// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-container {
    border: 1px solid #e0e0e0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    max-width: 400px;
}

.service-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
}

.service-title {
    font-weight: 600;
    margin-bottom: 10px;
}

.service-description {
    text-align: center;
    font-size: 16px;
    color: #999;
}
`, "",{"version":3,"sources":["webpack://./src/components/Service.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,wCAAwC;IACxC,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf","sourcesContent":[".service-container {\n    border: 1px solid #e0e0e0;\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    margin: 20px;\n    max-width: 400px;\n}\n\n.service-image {\n    width: 100%;\n    height: 150px;\n    object-fit: cover;\n    border-radius: 8px;\n    margin-bottom: 15px;\n}\n\n.service-title {\n    font-weight: 600;\n    margin-bottom: 10px;\n}\n\n.service-description {\n    text-align: center;\n    font-size: 16px;\n    color: #999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
